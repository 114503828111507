<template>
    <div>

        <Cargar :cargando="cargando"></Cargar>
        <div v-if="!cargando" class="row mb-4 ">
            <div class="col-md-12">

                <span class="h4"> Compentencias Académica </span> <br>
                <span class="text-secondary">
                   Conocimientos, habilidades, disposiciones y conductas que posee una persona, que le permiten la realización exitosa de una actividad.
                </span>

                <div class="my-3">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalcompetencia" @click="idcompetencia=null">+ Agregar</button>
                </div>

                <div class="row mb-2 bg-white p-3">
                    <div class="col-md-4 mb-2">
                        <label for="grupos" class="form-label">Grado / grupo: </label>
                        <select required v-model="grado" class="form-select">
                            <option value="null">-</option>
                            <option :value="item.grados.idacagradosgrupos" v-for="item in gradosAsignados" :key="item.grados.idacagradosgrupos"> 
                                {{item.grados.nombregradogrupo}}
                            </option>
                        </select>
                    </div>
    
                    <div class="col-md-3 mb-2">
                            <label for="grupos" class="form-label"> Periodo Académico: </label>
                            <select required v-model="periodo" class="form-select">
                                <option value="null">-</option>
                                <option :value="item.idperiodoacademico" :key="item.idperiodoacademico" v-for="item in listaperiodos">
                                    P{{item.numero}}  - {{formatoanio(item.fechainicio)}}
                                </option>
                            </select>
                    </div>

                    <div class="col-md-5 mb-2">
                        <label for="grupos" class="form-label">Asignatura:</label>
                        <select required v-model="asignatura" class="form-select">
                            <option value="null">-</option>
                            <option :value="item.asignaturas.idacaasignaturas" :key="item.asignaturas.idacaasignaturas" v-for="item in listaasignaturas">
                                {{item.asignaturas.descripcion}}
                            </option>                                        
                        </select>
                    </div>
 
                </div>
                
                <button class="btn btn-light border" @click="consultarcompetencias()"> <i class="far fa-list-alt me-2"></i> Mostrar Todo </button>

                <div class="mt-3 card p-3">
                    <table class="table mb-0">
                        <tbody>                        
                            <tr v-for="(item, index) in listacompentencias" :key="index">
                                <td style="width: 8px;" :style="'background-color: '+item.asignaturas.color"></td>
                                <td class="text-secondary" style="min-width: 60px;">
                                    <!-- fecha -->
                                    <span class="h5 titulosg">{{formatodia(item.created_at)}}</span> <br>
                                    <span style="font-size: 10px;"> {{formatoFecha(item.created_at)}}</span> 
                                </td>
                                <td>
                                    <span class="text-uppercase">{{item.asignaturas.descripcion}}</span>
                                   <div v-html="item.descripcion">  </div> 
                                    <span class=" text-secondary fw-light" > {{item.personas.nombres}} {{item.personas.apellidos}}</span> 
                                </td>
                                <td> <span class="text-uppercase fw-light"> {{item.grados.nombregradogrupo}} </span>  </td>                                    
                                <td><span class="badge bg-ligth border text-secondary" title="Periodo"> P{{item.periodo.numero}} </span></td>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm me-2 " title="Editar"  data-bs-toggle="modal" data-bs-target="#modalcompetencia" @click="editarcompetencia(item.idacalogros)"> <i class="fas fa-edit"></i> </button>
                                    <button class="btn btn-outline-secondary btn-sm" title="Eliminar" @click="eliminarcompetencia(item.idacalogros)" > <i class="far fa-trash-alt"></i> </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>      
                


                  <!-- Modal -->
                  <div class="modal fade" id="modalcompetencia" tabindex="-1" aria-labelledby="modalcompetenciaLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                      <div class="modal-content">
                        <div class="modal-body p-4">
                            <form @submit.prevent="enviarFormulario" method="POST" >
                                    
                                    <span class="h5 titulosg" v-if="idcompetencia>0">Editar Competencia</span>  
                                    <span class="h5 titulosg" v-if="idcompetencia==null">Agregar Competencia</span>  
                                                    
                                    <div class="row mt-3">
                                    <div class="mb-2 col-md-6">
                                        <label for="grupos" class="form-label">Grado / grupo: </label>
                                        <select required v-model="grado" class="form-select">"
                                            <option :value="item.grados.idacagradosgrupos" v-for="item in gradosAsignados" :key="item.grados.idacagradosgrupos"> 
                                                {{item.grados.nombregradogrupo}}
                                            </option>
                                        </select>
                                    </div>
                    
                                    <div class="col-md-3">
                                        <label for="grupos" class="form-label"> Periodo Académico Activo: </label>
                                            <select required v-model="periodo" class="form-select">
                                                <option :value="item.idperiodoacademico" :key="item.idperiodoacademico" v-for="item in listaperiodos">
                                                    P{{item.numero}} - {{formatoanio(item.fechainicio)}}
                                                </option>
                                            </select>
                                    </div>
        
                                    <div class="mb-2 ">
                                        <label for="grupos" class="form-label">Asignatura:</label>
                                        <select required v-model="asignatura" class="form-select">
                                            <option :value="item.asignaturas.idacaasignaturas" :key="item.asignaturas.idacaasignaturas" v-for="item in listaasignaturas">
                                                {{item.asignaturas.descripcion}}
                                            </option>                                        
                                        </select>
                                    </div>

                                    <div class="mb-2">
                                        <label for="grupos" class="form-label">Descripción:</label>
                                        <editor
                                        v-model="descripcion"
                                        api-key="fjq4jztxghzswoxpb6olz475m2jrssujbs1d09f2xr6r0aj3"
                                        :init="{
                                            height: 250,
                                            menubar: false,
                                            language: 'es',
                                            plugins: [
                                            'advlist autolink lists link '                                           
                                            ],
                                            toolbar:
                                            '\
                                            alignleft  alignjustify | \
                                            bullist numlist | removeformat '
                                        }"
                                        
                                        /> 
                                    </div>                                
            
                                    <div class="mt-3 text-end">
                                        <input type="hidden" v-model="idcompetencia">
                                        <button type="submit" class="btn btn-primary me-2" :disabled="btnguardar"><i class="fas fa-save me-2"  ></i> Guardar</button>   
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    </div>                                        
                                    </div>
            
                            </form>
                        </div>

                      </div>
                    </div>
                  </div>

            </div>
        </div>

    </div>
</template>

<script>

import { ref, reactive, onMounted, watch } from 'vue'
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'

import Cargar from "../../components/carga"
import moment from 'moment'

export default {
     components:{
        Cargar,
        'editor': Editor
    },
    setup(props) {

        const cargando = ref(false)
        const btnguardar = ref(false)
        const listacompentencias = ref([])
        const gradosAsignados = ref([])

        const listaperiodos = ref([])
        const listaasignaturas = ref([])

        const idcompetencia = ref(null)
        const grado = ref(null)
        const periodo = ref(null)
        const asignatura = ref(null)
        const descripcion = ref(null)

        const consultarcompetencias = async () => {
            let response = await axios.get('/api/competenciasregistradas')
            listacompentencias.value = response.data

                idcompetencia.value = null
                grado.value = null
                periodo.value = null
                asignatura.value = null
                descripcion.value = ""
        }

        const filtrarcompetencias = async () => {
            let response = await axios.get('/api/competenciasregistradas/'+grado.value+'/'+periodo.value+'/'+asignatura.value)
            listacompentencias.value = response.data
        }
        
        //grados asignados
        const consultagrados = async () => {
            let response = await axios.get('/api/gradosasignados/sinid')
            gradosAsignados.value = response.data
        }
        
        //periodosactivos
        const consultarperiodos = async () => {
            let response = await axios.get('/api/periodosacademicosactivos')
            listaperiodos.value = response.data
        }
                
        //asignaturas x grado
        const consultarasignaturas = async () => {
            let response = await axios.get('/api/asignaturasasignadasxgrado/sinid/'+grado.value)
            listaasignaturas.value = response.data
        }

        //asignaturas x grado
        const eliminarcompetencia = async (id) => {
            let response = await axios.get('/api/eliminarcompetencia/'+id)
            if(response.data == "ok"){
                swal("Ok!", "Registro Eliminado Correctamente!", "success")
                consultarcompetencias()
            }   
        }
        
        //consultar competencia por id
        const editarcompetencia = async (id) => {
            
            btnguardar.value = true

            let response = await axios.get('/api/consultarcompetencia/'+id)

                idcompetencia.value = response.data.idacalogros
                grado.value = response.data.acagradosgrupos_idacagradosgrupos
                periodo.value = response.data.acaperiodo_idperiodoacademico
                asignatura.value = response.data.acaasignaturas_idacaasignaturas
                descripcion.value = response.data.descripcion

            btnguardar.value = false
        }

        const enviarFormulario = async () => {
            
            btnguardar.value = true
            let url = ""
            if(idcompetencia.value == null){
                url = "/api/guardarcompetencia"
            }
            if(idcompetencia.value != null){
                url = "/api/actualizarcompetencia"
            }

            let response = await axios.post(url, 
            { 
                idcompetencia: idcompetencia.value, 
                grado: grado.value, 
                periodo: periodo.value, 
                asignatura: asignatura.value, 
                descripcion: descripcion.value 
            })

            if(response.data != "Error"){
                
                swal("Ok!", "Información actualizada Correctamente!", "success")
                consultarcompetencias()
            }  

            btnguardar.value = false

        }

        const formatoFecha = (date) => {
            return moment(date).format('MMM YYYY');
        }

        const formatodia = (date) => {
            return moment(date).format('D');
        }
        
        const formatoanio = (date) => {
            return moment(date).format('YYYY');
        }
        
        watch(grado, () =>{
            consultarasignaturas()
        })

        watch([grado, periodo, asignatura], () =>{
                filtrarcompetencias()
        })

        onMounted(() => {
            consultarcompetencias()
            consultagrados()
            consultarperiodos()
            consultarasignaturas()
        })

        
        return {
            btnguardar,
            cargando,
            listacompentencias,
            gradosAsignados,
            listaperiodos,
            listaasignaturas,
            idcompetencia,
            grado,
            periodo,
            asignatura,
            descripcion,

            consultarcompetencias,
            enviarFormulario,
            formatoFecha,
            formatodia,
            formatoanio,
            eliminarcompetencia,
            editarcompetencia
        }
    }
}
</script>

<style>


</style>
