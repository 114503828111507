<template>
    <div>
        <Cargar :cargando="cargando"></Cargar>
        <div v-if="!cargando" class="mb-4">

        <span class="h4 titulosg"> Calificaciones </span> <br>
        <span class="text-secondary"> Puede ingresar o modificar las calificaciones en los diferentes grados y asignaturas asignadas a su perfil. </span>

        <form @submit.prevent="enviarformulario"  method="POST">
        <div class="row mb-4  mt-3">

            <div class="col-md-5">
                
                <div class="p-4 bg-white" style="border-radius: 10px;">
                    <div class="mb-3">
                            <label for="periodo" class="form-label"> <strong> Periodo Académico: </strong> </label>
                            <select name="periodo" id="periodo" class="form-select" v-model="periodo" required>
                                <option v-for="item in periodosActivos" :key="item.idperiodoacademico" :value="item.idperiodoacademico">Periodo N° {{item.numero}}   -> {{item.fechainicio}} / {{item.fechafin}} </option>
                            </select>
                    </div>
                    <div class="mb-3">
                            <label for="grado" class="form-label"> <strong> Grado: </strong> </label>
                            <select name="grado" id="grado" class="form-select" v-model="grado"  required>
                                <option v-for="item in gradosAsignados" :key="item.grados.idacagradosgrupos" :value="item.grados.idacagradosgrupos">  {{item.grados.nombregradogrupo}}  </option>
                            </select>
                    </div>
                    <div class="">
                            <label for="asignatura" class="form-label"> <strong> Asignatura: </strong> </label>
                            <select name="asignatura" id="asignatura" class="form-select" v-model="asignatura"  :disabled="selectasignaturas" required>
                                <option v-for="item in asignaturasAsignadas" :key="item.asignaturas.idacaasignaturas" :value="item.asignaturas.idacaasignaturas">  {{item.asignaturas.descripcion}}  </option>
                            </select>
                            <div class="spinner-border spinner-border-sm ms-2 mt-2 text-primary" v-if="selectasignaturas" role="status"></div>
                    </div>
                </div> 

            </div>
            <div class="col-md-7">
                
                <div class="card card-body" style="height: 400px; overflow-y: auto;" >
                    <table class="table table-sm table-striped mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nombre del Estudiante   </th>
                                <th class="text-center">Calificación Actual</th>
                                <th>Calificación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in listaestudiantes" :key="item.idacamatricula">
                                <td class="align-middle">{{ index+1 }}</td>
                                <td class="text-capitalize align-middle"> {{item.nombres}} {{item.apellidos}} </td>
                                <td class="text-center"> 
                                    <span v-for="(calanterior, index) in calificacionesvigentes" :key="index">
                                       <span style="font-size: 11px;" class="me-2 badge bg-light border text-secondary" v-if="calanterior.personas_idpersonas == item.idpersonas">  
                                        {{calanterior.calificacion}}
                                    </span>
                                    </span>
                                </td>
                                
                                <td style="width: 15px;"> 
                                    <input  class="form-control form-control-sm" type="number"  v-model="calificacion[index]" max="5" min="0" step="0.1"> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mt-3 text-end">
 
                    <div class="alert alert-warning" v-if="resultado != ''">
                        {{resultado}}
                    </div>
        
                    <button type="submit" class="btn btn-sm btn-primary" :disabled="enviar">
                        <span class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" v-if="enviar"></span>
                        <i class="fas fa-save me-2"></i> Guardar Calificación
                    </button>
                </div>

            </div>
        </div>
        

        </form>

        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import axios from 'axios'

import Cargar from "../../components/carga"

export default {
    components:{
        Cargar
    },
    setup(props) {
        
        const cargando = ref(true)
        const selectasignaturas = ref(false)
        const enviar = ref(false)

        const periodosActivos = ref([]);
        const gradosAsignados = ref([]);
        const asignaturasAsignadas = ref([]);

        const periodo = ref()
        const grado = ref()
        const asignatura = ref()
        const listaestudiantes = ref()
        const calificacion = ref([])
        const calificacionesvigentes = ref([])
        const estudiantes = ref([])

        const resultado = ref('')

        const consultarInformacion = async () => {

            //Periodos Academicos
            let response = await axios.get('api/periodosacademicosactivos') 
            periodosActivos.value = response.data
          
            //grados asignados
            let gradosasignados = await axios.get('api/gradosasignados/sinid')
            gradosAsignados.value = gradosasignados.data

            cargando.value = false
        } 

        const consultarAsignaturas = async () => {

            selectasignaturas.value = true

            let asignaturasasignadas = await axios.get('/api/asignaturasasignadasxgrado/sinid/'+grado.value)
            asignaturasAsignadas.value = asignaturasasignadas.data

            if( asignaturasAsignadas.value.length>0){
                selectasignaturas.value = false
            }

        }

        const consultarEstudiantes = async () => {
            let respuestaestudiantes = await axios.get('/api/estudiantes/grado/'+grado.value)
            listaestudiantes.value = respuestaestudiantes.data
        }

        const calificacionesactuales = async () => {   
            let resultadocalificaciones = await axios.get('/api/consultarcalificaciones/'+periodo.value+'/'+asignatura.value)
            calificacionesvigentes.value = resultadocalificaciones.data
        }

        const enviarformulario = async () => {
            enviar.value = true
            let resultadoguardar = await axios.post('/api/guardarcalificaciones', { calificaciones: calificacion.value, estudiantes: listaestudiantes.value, periodo: periodo.value, grado:grado.value, asignatura: asignatura.value }) 
            resultado.value = resultadoguardar.data
            calificacionesactuales()
            enviar.value = false
        }


        const validateDecimal = (valor) => {
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (RE.test(valor)) {
                return RE;
            } else {
                return false;
            }
        }

        watch([asignatura, periodo],() =>{
            calificacion.value = []
            calificacionesactuales()
        })

        watch(grado,() =>{
            calificacion.value = []
            consultarEstudiantes()
            consultarAsignaturas()
        })

        onMounted(() => {
                consultarInformacion()
        })

        return {
            enviar,
            periodosActivos,
            gradosAsignados,
            asignaturasAsignadas,
            periodo,
            grado,
            asignatura,
            cargando,
            selectasignaturas,
            listaestudiantes,
            estudiantes,
            calificacion,
            calificacionesvigentes,
            resultado,

            consultarAsignaturas,
            enviarformulario
        }
    }
}
</script>

<style>

</style>
