<template>
        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>
</template>

<script>
export default {
    props:['cargando'],
    setup(props) {
        
        return {
        }
    }
}
</script>

<style>

</style>
