<template>
<div>
    <Cargar :cargando="cargando"></Cargar>
    <div v-if="!cargando" class="mb-5">

        <div>
            <span class="h4 titulosg"> Informes Académicos </span> <br>
            <span class="text-secondary">
                En esta sección puede generar los informes de forma masiva para los estudiantes, actualizar el estado de los informes y consultar los informes generados.
            </span> 
        </div>
        
        <div class="mt-3 bg-white card-body">

            <p>
                <strong>Generación de informes académicos</strong>
            </p>  
            <span class="text-secondary">Complete el siguiente formulario, los campos con * son necesarios para finalizar el registro</span>
            <form action="">

                <div class="row mb-2">
                    <label for="sede" class="col-md-2 col-form-label text-end">Institucion/Sede: * </label>
                    <div class="col-md-5 d-flex">
                        <select required class="form-select" v-model="sede">
                            <option v-for="item in listasedespermitidas" :key="item.idempresasede" :value="item.idempresasede"> {{item.nombreempresasede}} </option>
                        </select>
                        <span class="text-success ms-3 mt-2" v-if="sede!=null"><i class="fas fa-check"></i></span>
                    </div>
                </div>

                <div class="row mb-2">
                    <label for="grado" class="col-md-2 col-form-label text-end">Grado: * </label>
                    <div class="col-md-5 d-flex">
                        <select required name="" id="" class="form-select" v-model="grado" :disabled="sede==null">
                            <option v-for="item in listagradospermitidos" :key="item.idacagradosgrupos" :value="item.idacagradosgrupos"> {{item.nombregradogrupo}} </option>
                        </select> 
                        <span class="text-success ms-3 mt-2" v-if="grado!=null"><i class="fas fa-check"></i></span>
                    </div>
                </div>

                <div class="row mb-2">
                    <label for="periodo" class="col-md-2 col-form-label text-end">Periodo Académico: *</label>
                    <div class="col-md-3 d-flex">
                        <select required  class="form-select" v-model="periodo">
                            <option :value="item.idperiodoacademico" :key="item.idperiodoacademico" v-for="item in listaperiodos">
                                P{{item.numero}}  - {{formatoanio(item.fechainicio)}}
                            </option>
                        </select> 
                        <span class="text-success ms-3 mt-2" v-if="periodo!=null"><i class="fas fa-check"></i></span>
                    </div>
                    <div class="col-md-5">
                        <div class="mt-2" v-if="periodo!=null">
                            <i class="far fa-calendar-alt me-2"></i> Fecha de Informe: {{fecha}}
                        </div>                                    
                    </div>
                </div>

            </form>
        </div>

        <div class="my-3">
                Resultados: <span class="badge bg-primary">{{listaestudiantes.length}}</span> Estudiantes <br>
        </div>

        <!-- Estudiantes -->
        <div class="mt-3">
            <div class="col-md-12">
                <div class="card bg-white card" style="height: 300px; overflow-y: auto;">

                <table class="table mb-0 table-hover table-sm">
                    <tbody>
                        <tr v-for="(item, index) in listaestudiantes">
                            <td class="align-middle text-center" style="width: 5%;"> <strong>{{index+1}}</strong> </td>
                            <td class="align-middle" style="width: 30%;"> {{item.nombres}} {{item.apellidos}} </td>
                            <td class="align-middle text-secondary" style="width: 15%;"> Matrícula: {{item.vigencia}} </td>
                            <td>
                                <input type="text" class="form-control" v-model="comentarios[index]" placeholder="observación">
                            </td>
                        </tr>
                    </tbody>
                </table>

                </div>
            </div>         
        </div>

        <div class="bg-white p-3 my-3">
            <p>
                Firmas:
            </p>

            <input type="text" class="form-control" placeholder="Buscar" v-model="variablebusqueda">
            <div class="mt-2" v-if="variablebusqueda.length > 0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="item in listapersonas" :key="item.idpersonas">  
                        <button class="btn btn-light btn-sm me-3"  @click="agregarpersonasfirma(item.idpersonas, item.nombres+' '+item.apellidos)"> <i class="fas fa-user-plus"></i> Agregar</button>  {{item.nombres}} {{item.apellidos}} 
                    </li>
                  </ul>
           </div>

            <div class="mt-3 col-md-12 d-flex align-content-stretch flex-wrap">
                <div v-for="(item, index) in firmas" :key="index" class="px-3 py-2 me-2 mb-2 rounded border bg-outline-ligth" >
                    {{item.nombre}} 
                    <button @click="eliminarpersonafirma(index)" type="button" class="ms-3 btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>
            </div>
        </div>

        <div>
            <button class="btn btn-primary me-3" @click="generarinformes()" :disabled="btngenerar">
                <div class="spinner-border spinner-border-sm me-2" role="status" v-if="btngenerar"></div>
                <span v-if="!btngenerar"> <i class="fas fa-save me-2"></i> Generar Informes</span>
                <span v-if="btngenerar"> Cargando .... </span>
            </button>
            <button class="btn btn-danger" @click="eliminarinformes()" :disabled="btngenerar">
                <div class="spinner-border spinner-border-sm me-2" role="status" v-if="btngenerar"></div>
                <span v-if="!btngenerar"> <i class="far fa-trash-alt"></i> Eliminar Informes</span>
                <span v-if="btngenerar"> Cargando .... </span>
            </button>
        </div>
           

    </div>
</div>
</template>

<script>
import { ref,  onMounted, watch } from 'vue'
import axios from 'axios'

import Cargar from "../../components/carga"
import moment from 'moment'


export default {
    components:{
        Cargar
    },
    setup(props) {

        const cargando = ref(true)
        const btngenerar = ref(false)
        const variablebusqueda = ref('')

        const sede = ref(null)
        const grado = ref(null)
        const periodo = ref(null)
        const fecha = ref(null)
        const matriculas = ref([])
        const comentarios = ref([])

        const listaestudiantes = ref([])
        const listasedespermitidas = ref([])
        const listagradospermitidos = ref([])
        const listaperiodos = ref([])
        const listapersonas = ref([])

        const firmas = ref([])

        const consultaSedes = async () => {
            let response = await axios.get('api/sedesautorizadas')
            listasedespermitidas.value = response.data
            cargando.value = false //fin loading
        }

        const consultaGrados = async () => {
            let response = await axios.get('api/gradosasignadosxsede/sinid/'+sede.value)
            listagradospermitidos.value = response.data
            cargando.value = false //fin loading
        }

        const consultarperiodos = async () => {
            let response = await axios.get('/api/periodosacademicosactivos')
            listaperiodos.value = response.data
        }
                   
        const consultarEstudiantes = async () => {

            let response = await axios.get('api/informesestudiantes/'+sede.value+'/'+grado.value)
            listaestudiantes.value = response.data     
        }
                   
        const buscarpersona = async () => {
            let response = await axios.post('api/busquedapersona', { data: variablebusqueda.value })
            listapersonas.value = response.data            
        }

        const agregarpersonasfirma = (id,nombre) => {

            const resultado = firmas.value.find( item => item.id == id)

            if(resultado === undefined){
                firmas.value.push({'id': id, 'nombre': nombre})
            } 
                variablebusqueda.value=""
        }

        const eliminarpersonafirma = (index) => {
            firmas.value.splice( index , 1 )
        }
                   
        //generar informes
        const generarinformes = async () => {
            
            btngenerar.value = true

            let response = await axios.post('api/generarinformes', {
                estudiantes: listaestudiantes.value,
                observaciones: comentarios.value,
                sede: sede.value,
                periodo: periodo.value,
                fecha: fecha.value,
                firmas: firmas.value
            })
            console.log(response.data)
            if(response.data != "Error"){
                
                swal("Ok!", "Informes Generados correctamente!", "success")
                
            }  

            btngenerar.value = false
         }

         //eliminar informes
        const eliminarinformes = async () => {
            
            btngenerar.value = true

            let response = await axios.post('api/eliminarinformes', {
                estudiantes: listaestudiantes.value,
                observaciones: comentarios.value,
                sede: sede.value,
                periodo: periodo.value,
                fecha: fecha.value,
                firmas: firmas.value
            })
            if(response.data != "Error"){
                
                swal("Ok!", response.data, "success")
                
            }  

            btngenerar.value = false
         }




        watch(sede,()=>{
            consultaGrados()
        })

        watch(variablebusqueda,()=>{
            buscarpersona()
        })

        watch([grado, periodo],()=>{
            
            if(grado.value != null && periodo.value != null){
                consultarEstudiantes()
            }

            if(periodo.value != null){
                fecha.value = listaperiodos.value.filter( item => item.idperiodoacademico == periodo.value)
                fecha.value = fecha.value[0].fechareunion;
            }
        })

        const formatoanio = (date) => {
            return moment(date).format('YYYY');
        }

        onMounted(()=>{
            consultaSedes()
            consultarperiodos()
            
        })
        
        return {
            cargando,
            btngenerar,
            variablebusqueda,

            sede,
            grado,
            periodo,
            fecha,
            matriculas,
            comentarios,

            listaestudiantes,
            listasedespermitidas,
            listagradospermitidos,
            listaperiodos,
            listapersonas,
            firmas,
            
            generarinformes,
            agregarpersonasfirma,
            eliminarpersonafirma,
            eliminarinformes,
            formatoanio
        }
    }
}
</script>

<style>

</style>
