<template>
    <div>

    <Cargar :cargando="cargando"></Cargar>

    <div if="!cargando" class="row mb-4">
        <div class="col-md-12">

            <span class="h4 titulosg"> Informes Académicos  </span>

            <div class="mt-4">
                <div class="list-group">
                    <a href="javascript:;" @click="consultarinforme(item.idacainformes)" v-for="item in listainformes" :key="item.idacainformes" class="p-3 list-group-item list-group-item-action">
                        
                        <img src="/imagenes/ico/licencias.svg" style="width: 30px;" class="float-start me-3" alt="">
                        <span class="h5 titulosg"> Informe N° {{item.numero}} </span><br>
                        <span class="h6 titulosg"> Periodo Académico: {{item.periodo.numero}}</span> <br>
                        <i class="far fa-calendar-alt"></i>  Fecha del Informe: {{ formatoFecha(item.fecha) }} <br>
                        <div class="text-secondary">{{item.observacion}}</div>
                    </a>
                </div>
            </div>

 
           <div class="row mt-3" v-if="persona.identificacion > 0">
               <div class="col-md-12">
                <div class="border p-3 bg-white shadow" style="border-radius: 10px;">

                    <div class="border text-center p-3 text-center">
                        <span class="h4 titulosg text-uppercase"> {{informe.nombreempresasede}}   </span> <br>
                        {{informe.slogan}} <br> <br>
                        {{informe.resolucion}}   
                    </div>

                    <div class="my-2 bg-light text-secondary h5 titulosg text-center p-3">INFORME DE EVALUACIÓN </div>

                    
                    <div class="border bg-white p-2 mb-1">
                        Fecha del Informe: <strong class="ms-3 text-capitalize"> {{formatoFecha(informe.fecha)}}</strong>
                    </div>
             
                   
                   <div class="row ">
                       <div class="col-md-6">
                           <div class="p-2 border rounded bg-white">
                               Estudiante: <br>
                               <span class="text-uppercase h5 text-uppercase"> {{persona.nombres}} {{persona.apellidos}} </span>  

                           </div>
                       </div>
                       <div class="col-md-3 px-1">
                           <div class="p-2 border rounded bg-white align-middle ">
                                Grado: <br>
                               <span class="text-uppercase h5 text-uppercase"> {{matricula.nombregradogrupo}} </span>
                           </div>
                       </div>
                       <div class="col-md-3">
                           <div class="p-2 border rounded bg-white align-middle">
                               Jornada: <br>
                               <span class="text-uppercase h5 text-uppercase"> {{matricula.jornada}} </span>
                           </div>
                       </div>
                   </div>


                   <div class="border bg-white mt-2">
                    <div v-for="(item, index) in calificaciones" :key="index" class="p-3 border-bottom">
                        
                        <div class="row">
                            <div class="col-md-8"><strong>{{item.asignaturas.descripcion}} </strong> </div>
                            <div class="col-md-1 titulosg h5 border text-center py-2 rounded">{{item.calificacion}}</div>
                            <div class="col-md-3 text-center"> 
                                <!-- mostramos el desempeño -->
                                <div v-for="itemdesempenio in desempenio"> 
                                    <div v-if="item.calificacion >= itemdesempenio.minimo &&  item.calificacion <= itemdesempenio.maximo  "> {{itemdesempenio.descripcion}} </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="itemlogros in logros.filter( x => x.acaasignaturas_idacaasignaturas == item.asignaturas.idacaasignaturas)">
                            <div v-html="itemlogros.descripcion">  </div>
                        </div>

                    </div>
                </div>  

               <div class="mt-3 border p-3 bg-white">
                   <strong>Observación:</strong> <br>
                   {{informe.observacion}}
               </div>

                </div>
               </div>


    
           </div>


        </div>
    </div>

</div>
</template>

<script>

import { ref, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'
import Cargar from "../../components/carga"

export default {
    components:{
        Cargar
    },
    setup(props) {

        const cargando = ref(true)

        const idinforme  = ref()
        const listainformes = ref([])
        
        const persona = ref({})
        const informe = ref({})
        const matricula = ref({})
        const calificaciones = ref([])
        const logros = ref([])
        const desempenio = ref([])
        const asistencia = ref([])

        const listarinformesxestudiante = () => {
            axios.get('/api/listainformesxestudiante').then( response => {
                listainformes.value = response.data
            } )

            cargando.value = false
        }
 

        const consultarinforme = async (id) => {
            
            cargando.value = true

            let response = await axios.get('/api/datosinformeacademico/'+id) 
           
            persona.value = response.data.persona
            informe.value = response.data.informe
            matricula.value = response.data.matricula
            calificaciones.value = response.data.calificaciones
            logros.value = response.data.logros
            desempenio.value = response.data.desempenio
            asistencia.value = response.data.asistencia

            cargando.value = false
         
        }

        const formatoFecha = (date) => {
            return moment(date).format('DD [de] MMMM [de] YYYY');
        }


        onMounted(() => {
            listarinformesxestudiante()
        })

        return{
            cargando,
            idinforme,
            
            listainformes,
            
            persona,
            informe,
            matricula,
            calificaciones,
            logros,
            desempenio,
            asistencia,

            formatoFecha,
            consultarinforme
        }
    }

}
</script>

<style>

</style>
